import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72910940"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md:h-full md:flex md:flex-col md:overflow-y-hidden" }
const _hoisted_2 = { class: "page-content__title bg-white py-4 px-4 sm:px-6 text-left shadow z-10" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "page-content__icon pr-2" }
const _hoisted_5 = { class: "text-xl md:text-2xl font-semibold text-gray-800 flex-1" }
const _hoisted_6 = {
  key: 0,
  class: "text-sm font-bold"
}
const _hoisted_7 = { class: "page-content__actions pl-2 flex" }
const _hoisted_8 = { class: "md:px-6 md:flex-1 md:overflow-y-auto" }
const _hoisted_9 = { class: "mb-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_link = _resolveComponent("snap-link")!
  const _component_NavigationTabs = _resolveComponent("NavigationTabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _createElementVNode("h1", _hoisted_5, [
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.subtitle), 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "actions")
        ])
      ]),
      (_ctx.backRoute)
        ? (_openBlock(), _createBlock(_component_snap_link, {
            key: 0,
            text: _ctx.backRoute.text ?? 'Back',
            icon: "arrow-left-solid",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.backRoute.route))),
            "sr-only": "srOnly"
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NavigationTabs, {
        class: "mt-2 -ml-4 -mb-6 pb-px w-full",
        type: "primary",
        routes: _ctx.routes,
        "onTab:selected": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('tab:selected', $event)))
      }, null, 8, ["routes"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
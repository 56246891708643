import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3ffef82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center px-5 py-2"
}
const _hoisted_2 = {
  key: 0,
  class: "flex-1 pr-2"
}
const _hoisted_3 = {
  key: 1,
  class: "flex-1 pr-2 text-base font-bold text-gray-800"
}
const _hoisted_4 = { class: "flex-1 pt-2 overflow-y-auto px-5 py-2" }
const _hoisted_5 = { class: "px-5 py-2 flex md:justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_link = _resolveComponent("snap-link")!
  const _component_snap_close_button = _resolveComponent("snap-close-button")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "dialog" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              role: "dialog",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (!_ctx.isModal && _ctx.close()), ["prevent"])),
              class: "dialog fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-2"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
            'inner flex flex-col bg-white rounded-xl h-full overflow-hidden',
            _ctx.extended ? 'w-full md:w-2/4' : 'w-full md:w-1/2 lg:w-1/3 xl:w-1/4',
            'md:h-auto',
          ]),
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                (_ctx.title || _ctx.isBack || !_ctx.isModal)
                  ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
                      (_ctx.isBack)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_snap_link, {
                              text: "Back",
                              size: "base",
                              variant: "default",
                              icon: "arrow-left-solid",
                              "icon-position": "left",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isBack)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
                        : _createCommentVNode("", true),
                      (!_ctx.isModal && !_ctx.isBack)
                        ? (_openBlock(), _createBlock(_component_snap_close_button, {
                            key: 2,
                            color: "gray",
                            onClick: _ctx.close
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("section", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "default", {
                    close: _ctx.close,
                    state: _ctx.state
                  })
                ]),
                _createElementVNode("footer", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "actions", {
                    close: _ctx.close,
                    state: _ctx.state
                  })
                ])
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}